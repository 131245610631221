import React from "react";
import "./review.css";
import Goolge from "../Images/Frame 1171279849.png"
import Add from "../Images/add.png"
import City from "../Images/location_city.png"
export const Review = () => {
return (
<div className="review">
<div className="review1">
<div className="review-wrapper">
<div className="review-2">
<div className="review-3">
<div className="text-wrapper">150+</div>
{/* <img className="add" alt="Add" src={Add} /> */}
</div>
<div className="text-wrapper-2">Happy Costumers</div>
</div>
</div>
<img className="line" alt="Line" src="line-21.svg" />
<img className="img" alt="review" src={Goolge} />
<img className="line" alt="Line" src="line-22.svg" />
<div className="review-wrapper">
<div className="review-4">
<img className="location-city" alt="Location city" src={City} />
<div className="text-wrapper-2">Available in 4 Cities</div>
</div>
</div>
</div>
</div>
);
};