import React from 'react'
import hero from '../Images/HeroImage.png'
import morya from '../Images/image 46.png'
import a1 from '../Images/image 47.png'
import a2 from '../Images/image 48.png'
import a3 from '../Images/fincorp.png'
import point from '../Images/herodots.png'
import './heropage.css'
function Heropage({settimedPopup}) {
  return (
    <>
    <main className='bg-color'>
    <div class="container">
      <img src={hero} alt=""/>
      <div class="hero-text">
        <h1>Your Dream Home, <br/>Our Expert Touch </h1> <br/>
        <p style={{display:"flex",alignItems:"baseline",gap:"10px"}}> <span>Home</span> <span className="type" style={{"--n":"3"}}>
  <span>
    <span>Design</span>
    <span>Construction</span>
    <span>Furnishing</span>
  </span>
</span>
        </p>
        <button class="button type1">
  <span class="btn-txt" onClick={settimedPopup}>Let's Build</span>
</button><br></br><br></br>
<img src={point}  className='pointsers' style={{width:"unset",height:"unset"}}/>
      </div>
    </div>
    <img src={point}  className='below' style={{width:"unset",height:"unset"}}/>
  </main>
  <div className='hero-footer'>
<div className='hero-footer-innerdiv'>
    <img src={morya} alt=""/>
    <p>Moyra Sariya</p>
</div>
<div className='hero-footer-innerdiv'>
    <img src={a1} alt=""/>
    <p>Ambuja Cement</p>
</div>
<div className='hero-footer-innerdiv'>
    <img src={a2} alt=""/>
    <p>India Today</p>
</div>
<div className='hero-footer-innerdiv'>
    <img src={a3} alt=""/>
    <p>Fincorp</p>
</div>
  </div>
  </>
  )
}

export default Heropage