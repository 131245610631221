import React from 'react'
import './image.css'
import T3 from '../Images/gallery/Frame 92.png'
import T4 from '../Images/gallery/Frame 92.png'
import T5 from '../Images/gallery/Frame 92.png'
import T6 from '../Images/gallery/Frame 92.png'
import T7 from '../Images/gallery/Frame 92.png'
function ImageGallery({isMobile}) {
  return (
   <div className={isMobile?"imagephone":"image-gallery"} style={{paddingTop:"80px"}}>
    <div style={{width:"100%",textAlign:"center"}}>
        <h1 style={{color:"black",fontSize:"38px"}}>A Home Like No Other</h1>
        <p>Our designs cater to a modern way of living & lifestyle-based upgrades.<br/>
Everything you need want in a home. This is the new standard.</p>
    </div>
     {isMobile?
     <div style={{all:"unset"}}>
     <div class="slider">
        <div class="slide">
            <p>Slide 1</p>
        </div>
        <div class="slide">
            <p>Slide 2</p>
        </div>
        <div class="slide">
            <p>Slide 3</p>
        </div>
        <div class="slide">
            <p>Slide 4</p>
        </div>
    </div>
     </div>:<section>
  <div class="card">
  <div class="card__img">  
    <img src="https://i.ibb.co/5FXYQK4/Frame-92.png" alt="Big Ben"/>
       <span><svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
</svg>Ketkar Villa</span>
    {/* <div class="card__overlay">
      <h2>London</h2>
      <p>Big Ben</p>
    </div> */}
    </div>
  </div>
      <div class="card">
  <div class="card__img">  
    <img src="https://i.ibb.co/jwrrGLr/Frame-95.png" alt="Eiffel Tower"/>
         
    {/* <div class="card__overlay">
      <h2>Paris</h2>
      <p>Eiffel Tower</p>
    </div> */}
    </div>
  </div>
      <div class="card">
  <div class="card__img">  
    <img src="https://i.ibb.co/gwQgCJD/Frame-96.png" alt="Colosseum"/>
        <span><svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
</svg>Khatri Villa</span>
    {/* <div class="card__overlay">
      <h2>Rome</h2>
      <p>Colosseum</p>
    </div> */}
    </div>
  </div>
      <div class="card">
  <div class="card__img">  
    <img src="https://i.ibb.co/gD6qDM3/Frame-97.png" alt="Pisa Tower"/>
          <span><svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
</svg>Dr. Gupta</span>
    {/* <div class="card__overlay">
      <h2>Pisa</h2>
      <p>Pisa Tower</p>
    </div> */}
    </div>
  </div>
      <div class="card">
  <div class="card__img">  
    <img src="https://i.ibb.co/hWm5XMR/Frame-94.png" alt=""/>
         <span><svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
</svg>Jain Villa</span>
    {/* <div class="card__overlay">
      <h2>New York</h2>
      <p>Statue of Liberty</p>
    </div> */}
    </div>
  </div>
      <div class="card">
  <div class="card__img">  
    <img src="https://i.ibb.co/3MHMm0R/Frame-93.png" alt="Sydney Opera House"/>
        <span><svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
</svg>Godha Villa</span>
    {/* <div class="card__overlay">
      <h2>Sydney</h2>
      <p>Sydney Opera House</p>
    </div> */}
    </div>
  </div>

</section>}
   </div>
  )
}

export default ImageGallery