import React from 'react'
import './slider.css'
import Flickity from 'react-flickity-component'

import T1 from '../Images/testimonial/T1e1.png'
import T2 from '../Images/testimonial/T1e2.png'
import T3 from '../Images/testimonial/T1I1.png'
import T4 from '../Images/testimonial/T1I2.png'
import T5 from '../Images/testimonial/T1I3.png'
import T6 from '../Images/testimonial/T1I4.png'
import T7 from '../Images/testimonial/T1I5.png'
import T8 from '../Images/testimonial/T1Avatar.png'

import A1 from '../Images/testimonial/T2E1.png'
import A2 from '../Images/testimonial/T2E2.png'
import A3 from '../Images/testimonial/T2I1.png'
import A4 from '../Images/testimonial/T2I2.png'
import A5 from '../Images/testimonial/T2I3.png'
import A6 from '../Images/testimonial/T2I4.png'
import A7 from '../Images/testimonial/T2I5.png'
import A8 from '../Images/testimonial/T2Avatar.png'

import B1 from '../Images/testimonial/T3E1.png'
import B2 from '../Images/testimonial/T3E2.png'
import B3 from '../Images/testimonial/T3I1.png'
import B4 from '../Images/testimonial/T3I2.png'
import B5 from '../Images/testimonial/T3I3.png'
import B6 from '../Images/testimonial/T3I4.png'
import B7 from '../Images/testimonial/T3I5.png'
import B8 from '../Images/testimonial/T3Avatar.png'

const flickityOptions = {
    initialIndex: 1
}
function Testimonial({isMobile}) {
  return (
    <div className='testimonial'>
     <div style={{width:"100%",textAlign:"center"}}>
        <h1 style={{color:"black",fontSize:"38px"}}>A home that's completely yours !</h1>
        <p>Explore our happy homeowners & their build stories</p>
    </div>
    <Flickity
      className={'carousel'} // default ''
      elementType={'div'} // default 'div'
      options={flickityOptions} // takes flickity options {}
      disableImagesLoaded={false} // default false
      reloadOnUpdate // default false
      static // default false
    >
    <div className='sliderinnerdiv'>
      <div className='testimonialdiv'>
       <div  style={{width:"100%",height:"100%",background:"#f7f6f3",position:"relative"}}>
       <Flickity
      className={'carousel'} // default ''
      elementType={'div'} // default 'div'
      options={flickityOptions} // takes flickity options {}
      disableImagesLoaded={false} // default false
      reloadOnUpdate // default false
      static // default false
    >
      <img src={T1} alt={"t1"} width={"100%"} height={"auto"} />
      <img src={T2} alt={"t2"} width={"100%"} height={"auto"}/>
    </Flickity><br/>
    <div className='iiiii' style={{width:"100%",display:"flex",padding:"50px",gap:"15px",position:"relative",}}>
      <img src={T8} alt={"t2"} width={"139px"} height={"139px"}/>
      <div style={{width:isMobile?"51%":"60%",textWrap:"wrap"}}>
        <h3 style={{fontSize:isMobile?"15px":""}}>Udit & saloni story</h3>
        <p style={{borderBottom:"1px solid #ddd",fontSize:isMobile?"14px":""}}>Ujjain</p>
        <p style={{fontSize:isMobile?"12px":""}}>Ideal solution to build a brand new home, 
Zero hassle and a very smooth experience!</p>
      </div>
    </div>

       </div>
       <div style={{width:"100%",height:"100%",background:"#f7f6f3",position:"relative"}}>
       <Flickity
      className={'carousel'} // default ''
      elementType={'div'} // default 'div'
      options={flickityOptions} // takes flickity options {}
      disableImagesLoaded={false} // default false
      reloadOnUpdate // default false
      static // default false
    >
      <img src={T3} alt={"t1"} width={"100%"} height={"auto"} />
      <img src={T4} alt={"t2"} width={"100%"} height={"auto"}/>
      <img src={T5} alt={"t2"} width={"100%"} height={"auto"}/>
      <img src={T6} alt={"t2"} width={"100%"} height={"auto"}/>
      <img src={T7} alt={"t2"} width={"100%"} height={"auto"}/>
    </Flickity><br/>
    <div className='iiiii' style={{width:"100%",display:"flex",padding:"50px",gap:"15px",position:"relative",borderLeft:"1px solid #ddd"}}>
      <div style={{width:isMobile?"90%":"70%",textWrap:"wrap"}}>
        <h3 style={{borderBottom:"1px solid #ddd"}}>Project details</h3>
        <table>
   
    <tr>
      <td>Plot Area</td>
      <td>1520sqft</td>
     
    </tr>
    <tr>
      <td>Total Cost</td>
      <td>45 lakhs</td>
        
    </tr>
    <tr>
      <td>Package Selected</td>
      <td>Premium</td>
      
    </tr>
  </table>
      </div>
    </div>


       </div>
      </div>
    </div>
    <div className='sliderinnerdiv'>
    <div className='testimonialdiv'>
       <div  style={{width:"100%",height:"100%",background:"#f7f6f3",position:"relative"}}>
       <Flickity
      className={'carousel'} // default ''
      elementType={'div'} // default 'div'
      options={flickityOptions} // takes flickity options {}
      disableImagesLoaded={false} // default false
      reloadOnUpdate // default false
      static // default false
    >
      <img src={A1} alt={"t1"} width={"100%"} height={"auto"} />
      <img src={A2} alt={"t2"} width={"100%"} height={"auto"}/>
    </Flickity><br/>
    <div className='iiiii' style={{width:"100%",display:"flex",padding:"50px",gap:"15px",position:"relative",}}>
      <img src={A8} alt={"t2"} width={"139px"} height={"139px"}/>
      <div style={{width:isMobile?"51%":"60%",textWrap:"wrap"}}>
        <h3 style={{fontSize:isMobile?"15px":""}}>Ajay & Suman’s Story</h3>
        <p style={{borderBottom:"1px solid #ddd",fontSize:isMobile?"14px":""}}>Indore</p>
        <p style={{fontSize:isMobile?"12px":""}}>Choosing BuildStory for our home construction was the best decision we made. Fantastic experience</p>
      </div>
    </div>

       </div>
       <div style={{width:"100%",height:"100%",background:"#f7f6f3",position:"relative"}}>
       <Flickity
      className={'carousel'} // default ''
      elementType={'div'} // default 'div'
      options={flickityOptions} // takes flickity options {}
      disableImagesLoaded={false} // default false
      reloadOnUpdate // default false
      static // default false
    >
      <img src={A3} alt={"t1"} width={"100%"} height={"auto"} />
      <img src={A4} alt={"t2"} width={"100%"} height={"auto"}/>
      <img src={A5} alt={"t2"} width={"100%"} height={"auto"}/>
      <img src={A6} alt={"t2"} width={"100%"} height={"auto"}/>
      <img src={A7} alt={"t2"} width={"100%"} height={"auto"}/>
    </Flickity><br/>
    <div className='iiiii' style={{width:"100%",display:"flex",padding:"50px",gap:"15px",position:"relative",borderLeft:"1px solid #ddd"}}>
      <div style={{width:isMobile?"90%":"70%",textWrap:"wrap"}}>
        <h3 style={{borderBottom:"1px solid #ddd"}}>Project details</h3>
        <table>
   
    <tr>
      <td>Plot Area</td>
      <td>1520sqft</td>
     
    </tr>
    <tr>
      <td>Total Cost</td>
      <td>45 lakhs</td>
        
    </tr>
    <tr>
      <td>Package Selected</td>
      <td>Premium</td>
      
    </tr>
  </table>
      </div>
    </div>


       </div>
      </div>
    </div>
    <div className='sliderinnerdiv'>
    <div className='testimonialdiv'>
       <div  style={{width:"100%",height:"100%",background:"#f7f6f3",position:"relative"}}>
       <Flickity
      className={'carousel'} // default ''
      elementType={'div'} // default 'div'
      options={flickityOptions} // takes flickity options {}
      disableImagesLoaded={false} // default false
      reloadOnUpdate // default false
      static // default false
    >
      <img src={B1} alt={"t1"} width={"100%"} height={"auto"} />
      <img src={B2} alt={"t2"} width={"100%"} height={"auto"}/>
    </Flickity><br/>
    <div className='iiiii' style={{width:"100%",display:"flex",padding:"50px",gap:"15px",position:"relative",}}>
      <img src={B8} alt={"t2"} width={"139px"} height={"139px"}/>
      <div style={{width:isMobile?"51%":"60%",textWrap:"wrap"}}>
        <h3 style={{fontSize:isMobile?"15px":"",fontSize:isMobile?"14px":""}}>UPiyush & Bhumika’s Story</h3>
        <p style={{borderBottom:"1px solid #ddd"}}>Indore</p>
        <p style={{fontSize:isMobile?"12px":""}}>We can't thank BuildStory enough for the exceptional experience they provided during the construction of our home.</p>
      </div>
    </div>

       </div>
       <div style={{width:"100%",height:"100%",background:"#f7f6f3",position:"relative"}}>
       <Flickity
      className={'carousel'} // default ''
      elementType={'div'} // default 'div'
      options={flickityOptions} // takes flickity options {}
      disableImagesLoaded={false} // default false
      reloadOnUpdate // default false
      static // default false
    >
      <img src={B3} alt={"t1"} width={"100%"} height={"auto"} />
      <img src={B4} alt={"t2"} width={"100%"} height={"auto"}/>
      <img src={B5} alt={"t2"} width={"100%"} height={"auto"}/>
      <img src={B6} alt={"t2"} width={"100%"} height={"auto"}/>
      <img src={B7} alt={"t2"} width={"100%"} height={"auto"}/>
    </Flickity><br/>
    <div className='iiiii' style={{width:"100%",display:"flex",padding:"50px",gap:"15px",position:"relative",borderLeft:"1px solid #ddd"}}>
      <div style={{width:isMobile?"90%":"70%",textWrap:"wrap"}}>
        <h3 style={{borderBottom:"1px solid #ddd"}}>Project details</h3>
        <table>
   
    <tr>
      <td>Plot Area</td>
      <td>1520sqft</td>
     
    </tr>
    <tr>
      <td>Total Cost</td>
      <td>45 lakhs</td>
        
    </tr>
    <tr>
      <td>Package Selected</td>
      <td>Premium</td>
      
    </tr>
  </table>
      </div>
    </div>


       </div>
      </div>
    </div>
    </Flickity>


    </div>
  )
}

export default Testimonial