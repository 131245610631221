import React from 'react'
import './credo.css'
import a1 from '../Images/Guarantee.png'
import a2 from '../Images/On time.png'
import a3 from '../Images/Shield.png'
import a4 from '../Images/Sketch.png'

function Credo() {
  return (
    <div className="frame-credo" style={{marginBottom:"100px"}}>
    <div className="credo">
      <div className="credo-2">
        <p className="reimagine-home">
          <span className="text-wrapper">Reimagine</span>
          <span className="span"> home building experience</span>
        </p>
        <p className="a-step-by-step">
          A step by step turnkey home construction solution
          <br />
          in a modern &amp; effortless way !
        </p>
      </div>
      <div className="surface">
        <div className="list">
          <div className="row">
            <div className="block">
              <div className="item-surface">
                <div className="image">
                  <img className="img" alt="Sketch" src={a4} />
                </div>
                <div className="description">
                  <div className="title">
                    <div className="title-2">Curated, Personalised Design</div>
                  </div>
                  <div className="text">
                    <p className="desc">Beautiful home designs for you &amp; your loved ones.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="item-surface-wrapper">
              <div className="item-surface">
                <div className="image">
                  <img className="img" alt="Shield" src={a3} />
                </div>
                <div className="description-2">
                  <div className="title-wrapper">
                    <div className="title-2">Guaranteed Pricing</div>
                  </div>
                  <div className="desc-wrapper">
                    <p className="p">
                      Pricing finalised from day zero.
                      <br />
                      No last minuted cost escalations
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="credo-wrapper">
              <div className="item-surface-2">
                <div className="image">
                  <div className="guarantee-wrapper">
                    <img className="img" alt="Guarantee" src={a1} />
                  </div>
                </div>
                <div className="description-3">
                  <div className="title-3">
                    <div className="title-4">Assured Quality Control</div>
                  </div>
                  <div className="desc-wrapper">
                    <p className="desc">270+ Quality (QASCON) Checks <br/>performed by team of experts</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="item-surface-wrapper">
              <div className="item-surface-2">
                <div className="image">
                  <div className="on-time-wrapper">
                    <img className="img" alt="On time" src={a2} />
                  </div>
                </div>
                <div className="description-4">
                  <div className="title-wrapper">
                    <div className="title-2">Zero Delays</div>
                  </div>
                  <div className="text-2">
                    <p className="desc">On time delivery with zero <br/>tolerance for delays</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Credo