import React from "react"
import "./faq.css"
import { useState } from "react"

const Faq = () => {
  const [faqSelected, setfaqSelected] = useState(null)
  const toggle = (i) => {
    if (i === faqSelected) setfaqSelected(null)
    else setfaqSelected(i)
  }

  
  const data =  [
    {
      question:"How much will it cost to build a house with Build Story? ",
      answer:"Build Story provides detailed project costing upfront, tailored to your specific needs and preferences. The cost will depend on factors such as design complexity, materials, and your desired features. Contact us for a personalized quote. ",
    },
    {
      question:"What is the process of building the house going to be?",
      answer:"The building process with Build Story involves a clear project vision from Day 1, a transparent and quality-checked process, and innovative home designs. Our experienced contractors ensure your project is handled professionally from conception to completion. Book a free consultation for a detailed overview. ",
    },
    {
      question:"How often will I hear from my project manager?",
      answer:"Your salesperson and project manager are in communication throughout the pre-construction process. During construction, your project manager will provide weekly updates with photos and schedule on-site walk-throughs. Your team is reachable Monday – Friday from 8 am-5 pm and will respond within one business day"
    },
  ]
  

  return (
    <>
      <div className="faq-wrapper">
        <div className="faq-accordion">
          <h1>Frequently Asked Questions</h1>
          {data.map((item, i) => (
            <div className="faq-item" key={i}>
              <div className="faq-item-title" onClick={() => toggle(i)}>
                <h2>{item.question}</h2>
                <span>{faqSelected === i ? "-" : "+"}</span>
              </div>
              <div
                className={
                  faqSelected === i
                    ? "faq-item-content faq-item-show"
                    : "faq-item-content"
                }
              >
                <p>{item.answer}</p>
              </div>
            </div>
          ))}
        </div>
        {/* <button>View More</button> */}
      </div>
    </>
  )
}

export default Faq