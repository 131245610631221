import React from 'react'
import './package.css'
import img1 from '../Images/Frame 60.png'
function Package() {
  return (
<>
<div class="package-container mt-3">
	<div class="package">
		<div class="package__item">
			<div class="package__header">
				<div class="package__name">Budget Package</div>
			</div>
			<div class="package__body">
				<div class="package__price-package-container">
					<div class="package__price">
                        <img src={img1} alt='image' />
                    </div>
				</div>
				<ul>
					<li>User Account (1)</li>
					<li>Startup filter and list view</li>
					<li>Investor filter and list view</li>
				</ul>
			</div>
			<div class="package__footer">
				<button>Get Started</button>
			</div>
		</div>
		<div class="package__item">
			<div class="package__header">
				<div class="package__name">Budget Package</div>
			</div>
			<div class="package__body">
				<div class="package__price-package-container">
					<div class="package__price">
                        <img src={img1} alt='image' />
                    </div>
				</div>
				<ul>
					<li>User Account (1)</li>
					<li>Startup filter and list view</li>
					<li>Investor filter and list view</li>
				</ul>
			</div>
			<div class="package__footer">
				<button>Get Started</button>
			</div>
		</div>
		<div class="package__item">
			<div class="package__header">
				<div class="package__name">Budget Package</div>
			</div>
			<div class="package__body">
				<div class="package__price-package-container">
					<div class="package__price">
                        <img src={img1} alt='image' />
                    </div>
				</div>
				<ul>
					<li>User Account (1)</li>
					<li>Startup filter and list view</li>
					<li>Investor filter and list view</li>
				</ul>
			</div>
			<div class="package__footer">
				<button>Get Started</button>
			</div>
		</div>
		
	</div>
</div>
</>
  )
}

export default Package