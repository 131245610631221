import React,{useEffect,useState} from 'react'
import './popup.css'
// import logo1 from '../img/101.png';
import axios from 'axios';

function Popup({timedPopup, settimedPopup}) {

  const close=()=>{
    settimedPopup(false)

  }
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    name:"",
    email:"",
    phone:"",
    location:""
  });
  const handleChange = (e) => {
    setData((data) => ({ ...data, [e.target.name]: e.target.value }));
  };
  const handleSubmit = async(e)=>{
    e.preventDefault();
    console.log(data);
    const form = document.forms['formName']
    try {
      const url = `https://sheet.best/api/sheets/f95251fc-e945-4e8e-9079-313cf20b4a30`
      // const corsUrl=`https://cors-anywhere.herokuapp.com${url}`
      const res = await axios.post(url,data)
      // setData(res.data.data.docs)
      setLoading(true)
      setTimeout(()=>{
        settimedPopup(false)
        setLoading(false)
       },5000)
      
    } catch (error) {
      // setLoading(false)
      alert(error)
    }
  }
  return(timedPopup)? (
    <div style={{width:"100vw",height:"100vh",background: "rgba(0, 0, 0, 0.30)",backdropFilter:"blur(9px)",position:"fixed",zIndex:"1000",top:"0"}}>   
       <div class="popup">
    {loading?<>
      <div class="wrapper-1">
    <div class="wrapper-2">
      <h1>Thank you !</h1>
      <p>Thanks for filling out the details  </p>
      <p>We will get in touch with you soon  </p>
      
    </div>
</div>
    
    
    </>:<div class="contentBox">
      {/* {props.children} */}
        <div class="close1" onClick={close} ><img width="25" height="25" src="https://img.icons8.com/ios/50/delete-sign--v1.png" alt="delete-sign--v1"/></div>
       <div class="imgBx">
        {/* <img src={logo1}/> */}
       </div>
       <div class="content">
       <div class="login-box">
  <h2>Talk to our Experts</h2>
  <form onSubmit={handleSubmit}>
    <div class="user-box">
      <input type="text" name="name" re value={data?.name} onChange={handleChange} required={true}/>
      <label>Name</label>
    </div>
    <div class="user-box">
      <input type="text" name="phone" value={data?.phone} onChange={handleChange} required={true}/>
      <label>Phone</label>
    </div>
    <div class="user-box">
      <input type="location" name="location" value={data?.location} onChange={handleChange} required={true}/>
      <label>Location of Plot ?</label>
    </div>
    <div class="user-box">
      <input type="email" name="email" value={data?.email} onChange={handleChange} required={true}/>
      <label>Email</label>
    </div>
    <button type='submit'>
      Submit
    </button>
  </form>
</div>
       </div>
    </div>}
</div>
</div>

  ):"";
}

export default Popup