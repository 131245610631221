import React from 'react'
import './header.css'
import logo from '../Images/Logo.svg'
import phone from '../Images/icons8-phone-24.png'
import location from '../Images/icons8-location-50.png'
function Header({settimedPopup}) {
  return (
    <div class="nav">
  <input type="checkbox" id="nav-check" />
  <div class="nav-header">
    <div class="nav-title">
     <img src={logo} alt="logo" />
    </div>
  </div>
  <div class="nav-btn">
    <label for="nav-check">
      <span></span>
      <span></span>
      <span></span>
    </label>
  </div>
  
  <div class="nav-links">
    <a onClick={(e)=>e.preventDefault()} className='headlinkstyle' ><img width="18" height="18" src={location} alt="marker--v1"/>Indore</a>
    <a href="tel:918319735783" className='headlinkstyle' style={{marginRight:"25px"}}><img width="18" height="18" src={phone} alt="phone"/> +91 83197-35783</a>
     <a onClick={settimedPopup} className='nav-btn' target="_blank">Get Free Consultation</a>
  </div>
</div>
  )
}

export default Header