import { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import Header from './components/Header';
import Heropage from './components/Heropage';
import Faq from './components/faq';
import Footer from './components/footer';
import img1 from './Images/Frame 1171279861.png'
import img2 from './Images/Frame 1171279863.png'
import img3 from './Images/Frame 1171279916.png'
import img4 from './Images/mobile.png'
import Package from './components/package';
import Popup from './components/Popup';
import { Review } from './components/Review';
import { Comparision } from './components/Comparision';
import ImageGallery from './components/ImageGallery';
import Team from './components/Team';
import Credo from './components/Credo';
import Process from './components/Process';
import Testimonial from './components/Testimonial';
function App() {
  const [width, setWidth] = useState(window.innerWidth);
 const isMobile= 900 > width;
  const [timedPopup, settimedPopup] = useState(false);
  useEffect(() => {
   setTimeout(()=>{
    settimedPopup(true)
   },5000)
  }, []);
  const handleWindowResize = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, [window.innerWidth]);
  return (
    <div className="App" style={{overflow:"hidden"}}>
      <Popup timedPopup={timedPopup} settimedPopup={settimedPopup}/>
      <Header settimedPopup={settimedPopup}/>
      <Heropage settimedPopup={settimedPopup}/>
      <Credo />
      <div style={{all:"unset"}}>
      <Process />
       <Testimonial isMobile={isMobile}/>
       </div>
      {/* <Package /> */}
      <div class="banner" onClick={()=>settimedPopup(true)}>
  <p>Want to build you own story? <em>Click Here</em></p>
</div>

      {/* <img   className="responsive"src={img2} /> */}
      <ImageGallery isMobile={isMobile}/>
      <Review />
      <img   className="responsive"src={ isMobile  ?img4:img3} />
      <Comparision />
      <Team />
      <Faq />
      <Footer />
    </div>
  );
}

export default App;
